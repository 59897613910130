var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loadingContent)?_c('SkeletonDoubleSelect'):_c('div',{staticClass:"card card-custom"},[_c('div',{staticClass:"d-flex mb-3"},[_c('b-form-group',{staticClass:"w-50 m-5",attrs:{"label":"Établissement","label-for":"customerSelect","data-app":""}},[_c('CustomSelect',{attrs:{"id":"customerSelect","options":_vm.optionsCustomer,"searchable":"","labelSearchPlaceholder":"Rechercher un établissement","labelNotFound":"Aucun résultat pour"},on:{"input":function($event){return _vm.addData()}},model:{value:(_vm.filterCustomer),callback:function ($$v) {_vm.filterCustomer=$$v},expression:"filterCustomer"}})],1),_c('b-form-group',{staticClass:"w-50 m-5",attrs:{"label":"Code produit","label-for":"codeProduitSelect","data-app":""}},[_c('CustomSelect',{attrs:{"id":"codeProduitSelect","options":_vm.optionsCodeProduit,"searchable":"","labelSearchPlaceholder":"Rechercher un code produit","labelNotFound":"Aucun résultat pour"},on:{"input":function($event){return _vm.addData()}},model:{value:(_vm.filterCodeProduit),callback:function ($$v) {_vm.filterCodeProduit=$$v},expression:"filterCodeProduit"}})],1)],1),(_vm.currentUser.role !== 'Utilisateur')?_c('div',[(_vm.displayVTable)?_c('v-data-table',{attrs:{"headers":_vm.fields,"items":_vm.items,"loading":_vm.loading,"loading-text":"Chargement en cours ...","responsive":"","hide-default-footer":true,"no-data-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"item.moyenne",fn:function({ item }){return [_vm._v(" "+_vm._s(item.taux)+"% ")]}},{key:"item.action",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
                            name: 'internship-notice.list',
                            params: {
                                customer_id: item.cli_id,
                                code_produit_id: item.pro_id,
                                selectParams: { customer_id: _vm.filterCustomer, code_produit_id: _vm.filterCodeProduit }
                            }
                        }}},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Voir le détail'),expression:"'Voir le détail'",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-circle btn-light btn-sm"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Visible.svg"}})],1)],1)]}},{key:"no-results",fn:function(){return [_c('span',[_vm._v("Aucun résultat.")])]},proxy:true}],null,false,183730109)}):_vm._e(),(_vm.displayWarning)?_c('div',{staticClass:"d-flex justify-content-center align-items-center mb-5"},[_vm._v(" Veuillez sélectionner un établissement et / ou un code produit ")]):_vm._e()],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }