<template>
    <div>
        <SkeletonDoubleSelect v-if="loadingContent" />
        <div class="card card-custom" v-else>
            <div class="d-flex mb-3">
                <b-form-group label="Établissement" label-for="customerSelect" class="w-50 m-5" data-app>
                    <CustomSelect
                        @input="addData()"
                        id="customerSelect"
                        :options="optionsCustomer"
                        v-model="filterCustomer"
                        searchable
                        labelSearchPlaceholder="Rechercher un établissement"
                        labelNotFound="Aucun résultat pour"
                    />
                </b-form-group>
                <b-form-group label="Code produit" label-for="codeProduitSelect" class="w-50 m-5" data-app>
                    <CustomSelect
                        @input="addData()"
                        id="codeProduitSelect"
                        :options="optionsCodeProduit"
                        v-model="filterCodeProduit"
                        searchable
                        labelSearchPlaceholder="Rechercher un code produit"
                        labelNotFound="Aucun résultat pour"
                    />
                </b-form-group>
            </div>
            <div v-if="currentUser.role !== 'Utilisateur'">
                <v-data-table
                    :headers="fields"
                    :items="items"
                    :loading="loading"
                    loading-text="Chargement en cours ..."
                    responsive
                    :hide-default-footer="true"
                    v-if="displayVTable"
                    no-data-text="Aucun résultat"
                >
                    <template v-slot:item.moyenne="{ item }"> {{ item.taux }}% </template>
                    <template v-slot:item.action="{ item }">
                        <router-link
                            :to="{
                                name: 'internship-notice.list',
                                params: {
                                    customer_id: item.cli_id,
                                    code_produit_id: item.pro_id,
                                    selectParams: { customer_id: filterCustomer, code_produit_id: filterCodeProduit }
                                }
                            }"
                        >
                            <b-button class="btn btn-icon btn-circle btn-light btn-sm" v-b-tooltip.hover="'Voir le détail'">
                                <inline-svg src="/media/svg/icons/General/Visible.svg"></inline-svg>
                            </b-button>
                        </router-link>
                    </template>
                    <template v-slot:no-results>
                        <span>Aucun résultat.</span>
                    </template>
                </v-data-table>
                <div v-if="displayWarning" class="d-flex justify-content-center align-items-center mb-5">
                    Veuillez sélectionner un établissement et / ou un code produit
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { SET_PAGETITLE } from '@/core/services/store/pagetitle.module'
import SkeletonDoubleSelect from '@/view/content/skeleton/SkeletonDoubleSelect.vue'
import VSelect from '@alfsnd/vue-bootstrap-select'
import { LIST_CUSTOMERS_WITH_FILLIALE } from '@/core/services/store/api/customer'
import { LIST_CLIENTS_PRODUITS } from '@/core/services/store/api-orion/clientsProduits'
import { GET_SOURCE_EXT } from '@/core/services/store/api/tableSource'
import { mapGetters } from 'vuex'
import { LIST_AVIS_STAGIAIRES } from '@/core/services/store/api-orion/avisStagiaires'

export default {
    props: {
        selectParams: {
            type: Object,
            required: false
        }
    },
    components: { CustomSelect: VSelect, SkeletonDoubleSelect },
    data() {
        return {
            items: [],
            displayWarning: true,
            displayVTable: false,
            loadingContent: true,
            loading: true,
            filterCustomer: { text: 'Tous les établissements', value: '' },
            optionsCustomer: [],
            filterCodeProduit: { text: 'Tous les codes produit', value: '' },
            optionsCodeProduit: [],
            fields: [
                { text: this.$t('INTERNSHIP_NOTICE.GENERAL.PRODUCT_CODE'), value: 'pro_libelle' },
                { text: this.$t('INTERNSHIP_NOTICE.GENERAL.CUSTOMER'), value: 'cli_nom' },
                { text: this.$t('INTERNSHIP_NOTICE.GENERAL.AVERAGE_NOTE'), value: 'moyenne' },
                { text: '', value: 'action', sortable: false, align: 'center' }
            ]
        }
    },
    async mounted() {
        this.setPageTitle()
        this.$store.dispatch(LIST_CUSTOMERS_WITH_FILLIALE, this.$store.getters.currentUser.customer.id).then(result => {
            result.forEach(customer => {
                this.optionsCustomer.push({
                    text: customer.name + ' (' + customer.code + ')',
                    value: customer.id
                })
                this.optionsCustomer.sort((a, b) => a.text.localeCompare(b.text))
            })
            this.optionsCustomer.unshift({
                text: 'Tous les établissements',
                value: ''
            })
        })
        let params = {
            tablename: 'customers',
            tableid: this.$store.getters.currentUser.customer.id
        }
        await this.$store.dispatch(GET_SOURCE_EXT, { params }).then(async result => {
            await this.$store.dispatch(LIST_CLIENTS_PRODUITS, result.extid).then(result => {
                result.forEach(codeProduit => {
                    this.optionsCodeProduit.push({
                        text: codeProduit.pro_libelle,
                        value: codeProduit.pro_id
                    })
                    this.optionsCodeProduit.sort((a, b) => a.text.localeCompare(b.text))
                })

                this.optionsCodeProduit.unshift({
                    text: 'Tous les codes produit',
                    value: ''
                })
            })
        })
    },
    updated() {
        this.displayTable()
    },
    methods: {
        setPageTitle() {
            this.$store.dispatch(SET_PAGETITLE, this.$t('MENU.INTERNSHIP_NOTICE'))
        },
        async addData() {
            this.items = []
            this.loading = true
            let filter = {
                customer: this.filterCustomer.value,
                codeProduit: this.filterCodeProduit.value
            }
            if (filter.customer === '') {
                filter.customer = null
            }
            if (filter.codeProduit === '') {
                filter.codeProduit = null
            }
            if (filter.customer !== null || filter.codeProduit !== null) {
                if (filter.customer !== null) {
                    let params = {
                        tablename: 'customers',
                        tableid: filter.customer
                    }
                    await this.$store.dispatch(GET_SOURCE_EXT, { params }).then(async result => {
                        filter.customer = result.extid
                    })
                }
                let data = {
                    customerId: filter.customer,
                    codeProduitId: filter.codeProduit
                }
                await this.$store.dispatch(LIST_AVIS_STAGIAIRES, { data }).then(responses => {
                    this.items = Object.values(responses)
                    this.loading = false
                })
            }
        },
        displayTable() {
            if (this.filterCodeProduit.value || this.filterCustomer.value) {
                this.displayVTable = true
                this.displayWarning = false
            } else {
                this.displayVTable = false
                this.displayWarning = true
            }
        }
    },
    computed: {
        ...mapGetters({
            currentUser: 'currentUser'
        })
    },
    watch: {
        optionsCodeProduit() {
            if (this.optionsCodeProduit.length > 0 && this.optionsCustomer.length > 0) {
                this.loadingContent = false
                if (this.selectParams && this.selectParams.customer_id && this.selectParams.code_produit_id) {
                    this.filterCustomer = this.selectParams.customer_id
                    this.filterCodeProduit = this.selectParams.code_produit_id
                    this.addData()
                }
            }
            if (this.selectParams && this.selectParams.code_produit_id && this.selectParams.customer_id === '') {
                this.filterCodeProduit = this.selectParams.code_produit_id
                this.addData()
            }
        },
        optionsCustomer() {
            if (this.optionsCodeProduit.length > 0 && this.optionsCustomer.length > 0) {
                this.loadingContent = false
                if (this.selectParams && this.selectParams.customer_id && this.selectParams.code_produit_id) {
                    this.filterCustomer = this.selectParams.customer_id
                    this.filterCodeProduit = this.selectParams.code_produit_id
                    this.addData()
                }
            }
            if (this.selectParams && this.selectParams.customer_id && this.selectParams.code_produit_id === '') {
                this.filterCustomer = this.selectParams.customer_id
                this.addData()
            }
        }
    }
}
</script>
